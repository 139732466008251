@import "reset.css";

:root {
    --primary: coral;
    --white: whitesmoke;
    --qr-size: 250px;
    --border-radius: 8px;
    /*--primary: #ff6337;*/
}

html, body {
    font-family: 'Roboto', sans-serif !important;
    height: 100vh;
    background-color: var(--primary);
    /*color: #ffdee6;*/
}

h1 {
    font-size: 2.5rem;
    white-space: nowrap;
}

h2 {
    font-size: 2rem;
    font-weight: bold;
}

h3 {
    font-size: 1.5rem;
    margin: 1.5rem auto;
}

#banner {
    /*background-color: #3b3b3b;*/
    color: var(--white);
    padding: 4rem;
    text-align: center;
}

#banner h1 {
    font-size: 4rem !important;
}

#container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#box-container {
    margin: 0 auto;
    display: inline-block;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 2rem;
    transition: all 0.25s ease-in-out;
    text-align: center;
    /*box-shadow: 0 0 0 5px coral;*/
}

.hide {
    display: none;
}

#top-info {
    display: flex;
}

.title {
    text-align: left;
    margin-right: 2rem;
}

.amount {
    text-align: right;
    white-space: nowrap;
    font-size: 2.5rem;
    color: var(--primary);
    font-weight: bold;
}

ul {
    list-style-type: none;
}

li {
    display: inline;
    margin: auto 1rem;
}

li:first-of-type {
    margin-left: 0;
}

li img {
    /*width: 4rem;*/
    height: 4rem;
}

p {
    margin-bottom: 0.75rem;
}

#barcode {
    padding: 1rem;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 0 5px 1px rgba(117,117,117,1);
    height: fit-content;
}

#barcode img {
    width: var(--qr-size);
    height: var(--qr-size);
    filter:
        /* for demonstration purposes; originals not entirely black */
            contrast(1000%)
                /* black to white */
            invert(100%)
                /* white to off-white */
            sepia(100%)
                /* off-white to yellow */
            saturate(10000%)
                /* do whatever you want with yellow */
            hue-rotate(290deg)
                /* invert again */
            invert(100%);
}

#details {
    padding-left: 2rem;
}

#details p {
    text-align: left;
    font-size: 1rem;
}

.tiny-box {
    border-radius: var(--border-radius);
    background-color: white;
    padding: 0.75rem;
    box-shadow: 0 0 5px 1px rgba(117,117,117,1);
}

#address {
    font-weight: bold;
    margin-bottom: 1rem;
}

i.fa-copy {
    padding-left: 0.5rem;
}

#details .amount {
    font-size: 2rem;
    color: #4f4f4f;
}

#coin-span {
    font-weight: bold;
}

.warn {
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
    color: orangered;
}

#confirm {
    border: 0;
    border-radius: 0.70rem;
    padding: 0.9rem 1.5rem;
    background: initial;
    background-color: #67B379;
    border-color: #67B379;
    color: white;
    width: 100%;
    font-weight: bold;
    font-size: 1.75rem;
    transition: all 0.15s ease-in-out;
    margin-top: 1rem;
}

#confirm:focus {
    outline: 0;
}

#confirm:hover {
    background-color: #42834e;
    border-color: #42834e;
}

#coin-amount {
    font-weight: bold;
    font-size: 2rem;
    margin: 1.5rem auto;
}

/* Loader */
.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid var(--primary);
    border-color: var(--primary) transparent var(--primary) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin: 1.25rem auto;
}

#step1, #step2 {
    text-align: left;
}

#transaction {
    display: flex;
}